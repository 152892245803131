// Imports   $--litmostrybuy-
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap");

// Colors
$--litmos-primary-color: #ffc300;
$--litmos-primary-color-foreground: #000000;
$--litmos-primary-color-dark: #b08701;

$--litmos-secondary-color: #8233ff;
$--litmos-secondary-color-foreground: #ffffff;
$--litmos-secondary-color-dark: #3b0196;

$--litmos-accent-1: #009af9;
$--litmos-accent-1-foreground: #fff;
$--litmos-accent-1-dark: #005d96;
$--litmos-accent-1-light: #e5f5fe;
$--litmos-accent-1-transparent: rgba(0, 154, 249, 0.1);

$--litmostrybuy-primary-linear-gradient: linear-gradient(
  90deg,
  $--litmos-secondary-color,
  $--litmos-accent-1
);

// Fonts
$--litmostrybuy-primary-font: "Poppins";
$--litmostrybuy-font-size-xs: 14px;
$--litmostrybuy-font-size-ss: 16px;
$--litmostrybuy-font-size-s: 18px;
$--litmostrybuy-font-size-sm: 22px;
$--litmostrybuy-font-size-m: 24px;
$--litmostrybuy-font-size-ml: 27px;
$--litmostrybuy-font-size-l: 60px;

:root {
  // Layout
  --layout-width: 96vw;
  --layout-header-height: 64px;
  --default-padding-2: 2px 2px 2px 2px;
  // Header ICON
  --layout-icon-top: 7px;
  --layout-icon-left: 16px;
  --layout-icon-width: 160px;
  --layout-icon-height: 48px;
  // Title BAR
  --trybuy-title-height: 209px;
  --trybuy-title-margin-top: 48px;
  --trybuy-title-font-size: 3.6em;
  --trybuy-desc-margin-top: 11px;
  --trybuy-desc-font-size: 2.4em;
  // Purchase Cards
  --card-top: -33px;
  --feature-table-width: 698px;
  --cards-gap: 16px;
  // Cards
  --card-width: 337px;
  --card-height: 531px;
  --card-border-radius: 16px;
  --card-border-default: 1px solid #e8e8e8;
  --card-border-selected: 1px solid rgba(123, 56, 255, 0.31);
  --default-border: 1px solid #e8e8e8;
  // Info Card ICON
  --layout-icon-new-width: 263px;
  --layout-icon-new-height: 173px;
  --layout-icon-margin-top: 13px;
  --layout-icon-margin-left: 36px;
  // Inform Card text
  --inform-title-font-size: 1.6em;
  --inform-title-padding-top: 17px;
  --inform-title-padding-bottom: 15px;
  // Slider
  --slider-width: 143px;
  --slider-height: 6px;
  --slider-border-radius: 5px;
  --slider-margin-top: 27px;
  --slider-font-size: 1.7em;
  --slider-text-space: 8px;
  --slider-ball-size: 26px;
  --slider-text-padding: 4px 0;
  --sel-users-font-size: 2.4em;
  // Inform last text
  --inform-bottom-font-size: 1.2em;
  --inform-bottom-margin: 55px;
  // Sell Cards TITLE
  --card-title-height: 32px;
  --card-title-margin-top: 22px;
  --card-title-font-size: 2.4em;
  --card-title-desc-margin-top: 16px;
  --card-title-desc-font-size: 1.3em;
  --card-text-margin-left: 20px;
  --card-text-margin-right: 23px;
  // Buy Now Button
  --buynow-button-width: 288px;
  --buy-button-height: 64px;
  --button-area-padding: 22px;
  --month-word-font-size: 1.8em;
  --month-price-font-size: 1.333em;
  --purchase-price-font-size: 1.2em;
  --purchase-price-margin-top: 6px;
  // line
  --line-height: 1px;
  --default-border: 1px solid #e8e8e8;
  // Check ICON
  --layout-icon-check-feature-width: 26px;
  --layout-icon-check-feature-height: 23px;

  --purchase-products-margin-top: 14px;
  --purchase-products-font-size: 1.6em;
  --purchase-products-margin-top-p: 3px;
  --card-product-desc-font-size-p: 0.88em;
  --purchase-products-height-p: 26px;
  --feature-top: 44px;
  --feature-title-font-size: 2.0em;
  --default-feature-border: 1px solid #761ef3;
  //
  --layout-icon-feature-width: 16px;
  --layout-icon-feature-height: 16px;
  --feature-table-td-font-size: 1.5em;
  --feature-trl-title-top: 17px;
  --feature-trl-title-bottom: 15px;
  --feature-td-width: 300px;
  --feature-table-font-size: 1.6em;
  --feature-tr-title-top: 63px;
  --feature-tr-title-bottom: 17px;

  @media screen and (max-width: 750px) {
     --feature-table-width: 341px;
    // --card-width: 100%;
    --layout-header-height: 48px;
    // Header ICON
    --layout-icon-top: 5px;
    --layout-icon-left: 12px;
    --layout-icon-width: 120px;
    --layout-icon-height: 36px;
    // Title BAR
    --trybuy-title-height: 169px;
    --trybuy-title-margin-top: 36px;
    --trybuy-title-font-size: 2.4em;
    --trybuy-desc-margin-top: 9px;
    --trybuy-desc-font-size: 1.8em;
  }

  @media screen and (max-width: 340px) {
    --layout-width: 337px;
    --feature-table-width: 337px;
    // --card-width: 100%;
    --layout-header-height: 48px;
    // Header ICON
    --layout-icon-top: 5px;
    --layout-icon-left: 12px;
    --layout-icon-width: 120px;
    --layout-icon-height: 36px;
    // Title BAR
    --trybuy-title-height: 169px;
    --trybuy-title-margin-top: 36px;
    --trybuy-title-font-size: 2.4em;
    --trybuy-desc-margin-top: 9px;
    --trybuy-desc-font-size: 1.8em;
  }
 }
