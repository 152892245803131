@import "../../styles/global_variables";

.top-content {
  width: 100%;
  padding-left: var(--card-text-margin-left, 20px);
  padding-right: var(--card-text-margin-right, 23px);
  font-size: 10px;
}

.trybuy-purchase-features {
  position: absolute;
  width: var(--layout-width, 1434px);
  margin-left: var(--cards-margin-left, auto);
  margin-right: auto;  
  margin-bottom: var(--purchase-price-margin-bottom, 5px);
  box-sizing: border-box;
  text-align: center;
}

.trybuy-purchase-cards {
  position: relative;
  top: var(--card-top, 259px);
  width: var(--feature-table-width, 698px);
  margin: 0 auto;
}

.trybuy-purchase-cards-area {
  display: flex;
  box-sizing: border-box;
  margin: 0 auto;

  .ant-row {
    column-gap: var(--cards-gap, 13px);
    row-gap: var(--cards-gap, 13px);
    margin: 0 auto;
    font-size: 10px;

    .ant-col {
      font-size: 10px;
    }
  }
}

.ant-card-body .ant-row .ant-col {
  width: 100%;
}

.ant-card {
  width: var(--card-width, 448px);
  height: var(--card-height, 448px);
  padding: var(--default-padding-2, 2px 2px 2px 2px);
  border-radius: var(--card-border-radius, 8px);
  border: var(--card-border-selected, 1px solid #e8e8e8);
  background-color: #ffffff;
  box-sizing: border-box;

  .ant-card-body {
    padding: 0px;
  }
}

.trybuy-purchase-title {
  height: var(--card-title-height, 32px);
  margin-top: var(--card-title-margin-top, 22px);
  box-sizing: border-box;
  text-align: center;
  line-height: normal;
  font-size: var(--card-title-font-size, 24px);
  font-weight: 500;
  color: #333333;
  text-transform: capitalize;
}

.trybuy-purchase-suite-description {
  margin-top: var(--card-title-desc-margin-top, 16px);
  box-sizing: border-box;
  color: #333333;
  text-align: center;
  line-height: normal;
  font-size: var(--card-title-desc-font-size, 13px);
  padding-left: var(--card-text-margin-left, 20px);
  padding-right: var(--card-text-margin-right, 23px);
}

.trybuy-purchase-suite-include {
  width: var(--card-inner-width, 408px);
  height: var(--card-suite-description-height, 32px);
  margin-top: var(--card-title-margin-top, 20px);
  margin-bottom: var(--card-title-margin-top, 20px);
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  color: #333333;
  text-align: left;
  overflow: hidden;
  line-height: normal;
}

.line {
  width: 100%;
  height: var(--line-height, 1px);
  border: var(--default-border, 1px solid #e8e8e8);
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
}

.trybuy-purchase-products {
  box-sizing: border-box;
  color: #333333;
  text-align: left;
  overflow: hidden;
  line-height: normal;
  margin-top: var(--purchase-products-margin-top, 8px);
  margin-bottom: var(--purchase-products-margin-top, 8px);
  font-size: var(--purchase-products-font-size, 1.6em);
}

.trybuy-purchase-products p {
  margin-top: var(--purchase-products-margin-top-p, 3px);
  margin-bottom: 0;
  font-size: var(--card-product-desc-font-size-p, 0.9em);
  height: var(--purchase-products-height-p, 26px);
}

.trybuy-purchase-products-ext {
  height: var(--card-products-height, 430px);
}

.litmos-feature-check-icon {
  width: var(--layout-icon-check-feature-width, 15px);
  height: var(--layout-icon-check-feature-height, 11px);
}

.trybuy-purchase-product-title {
  margin-top: var(--card-product-title-margin-top, 16px);
  box-sizing: border-box;
  font-size: var(--card-product-title-font-size, 18px);
}

.trybuy-purchase-product-description {
  margin-top:   var(--card-product-desc-margin-top, 2px);
  margin-bottom: var(--card-product-desc-margin-bottom, 4px);
  box-sizing: border-box;
  font-weight: 300;
  font-size: var(--card-product-desc-font-size, 16px);
}

.trybuy-purchase-products-comment {
  height: var(--card-product-comm-height, 23px);
  width: var(--card-inner-width, 408px);
  margin-left: var(--card-text-margin-left, 20px);
  box-sizing: border-box;
  font-family: "Inter Light", "Inter", sans-serif;
  font-weight: 300;
  font-size: var(--card-product-comm-font-size, 12px);
  color: #333333;
  text-align: left;
  overflow: hidden;
  line-height: normal;
}

.trybuy-purchase-price {
  margin-left: var(--card-text-margin-left, 20px);
  width: var(--card-inner-width, 408px);
  margin-top: var(--purchase-price-margin-top, 10px);
  margin-bottom: var(--purchase-price-margin-bottom, 5px);
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Inter Light", "Inter", sans-serif;
  font-weight: 300;
  font-size: var(--purchase-price-font-size, 16px);
  color: #333333;
  text-align: left;
  line-height: normal;
}

.showMore {
  position: sticky;
  bottom: 0px;
  width: var(--card-inner-width, 408px);
  height: var(--show-more-height, 28px);
  padding: var(--show-more-padding, 12px 0px 0px 0px);
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
  font-family: "Inter Light", "Inter", sans-serif;
  font-weight: 300;
  font-size: var(--show-more-font-size, 14px);
  color: #0000ff;
  display: none;
}

.trybuy-purchase-cards-space {
  width: var(--layout-width, 74px);
}
