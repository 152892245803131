@import "../../styles/global_variables";

.ant-btn.ant-btn-lg {
  padding: 0px;
  width: 100%;
  height: var(--buy-button-height, 64px);
  display: flex;
  box-sizing: border-box;
  font-size: 10px;
}

.button-area {
  width: var(--buynow-button-width, 408px);
  margin-left: var(--card-text-margin-left, 20px);
  padding-top: var(--button-area-padding, 22px);
  padding-bottom: var(--button-area-padding, 22px);
}

.buy-now-button-enabled {  
  background: #7f35ff;
}

.buy-now-button-disabled {
  box-sizing: border-box;
}

.month-price {
  width: var(--month-width, 198px);
  margin: auto;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: var(--month-word-font-size, 18px);
  line-height: normal;
}

.month-price-value {
  font-size: var(--month-price-font-size, 36px);
}

.button-text {
  margin: auto;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-size: var(--buynow-button-font-size, 24px);
  text-align: center;
  line-height: normal;
}

.trybuy-purchase-products-price {
  box-sizing: border-box;
  color: #333333;
  text-align: center;
  overflow: hidden;
  line-height: normal;
  font-size: var(--purchase-price-font-size, 16px);
  margin-top: var(--purchase-price-margin-top, 6px);
}
