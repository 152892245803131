@import '../../styles/global_variables';

.trybuy-feature {      
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
  margin-top: var(--feature-top, 19px);
}

.feature-table-title{
  font-size: var(--feature-title-font-size, 48px);
  box-sizing: border-box;
  margin: 0 auto;
  text-align: left;
}

.feature-table-title span {
  border-bottom: var(--default-feature-border, 1px solid #761ef3);
}

.feature-table{
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}

.feature-tr-title {
  display: flex;
  box-sizing: border-box;
  text-align: left;
  border-bottom: 2px solid #e8e8e8;
  font-size: var(--feature-table-font-size, 1.6em);
  margin-top: var(--feature-tr-title-top, 63px);
  padding-bottom: var(--feature-tr-title-bottom, 17px);
}

.feature-tr-line {
  display: flex;
  box-sizing: border-box;
  text-align: left;
  border-bottom: 2px solid #e8e8e8;
  font-weight: 300;
  font-size: var(--feature-table-td-font-size, 1.5em);
  padding-top: var(--feature-trl-title-top, 17px);
  padding-bottom: var(--feature-trl-title-bottom, 15px);
}

.feature-tr {
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}

.feature-td {
  width: var(--feature-td-width, 300px);
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
  text-transform: capitalize;
}

.feature-td-icon{
  width: var(--feature-td-width, 300px);
  box-sizing: border-box;
  text-align: center;
  margin: auto auto;
}

.bottom-space {
  height: 30px;
}