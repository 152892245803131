@import "../../styles/global_variables";

.litmos-new-icon {
  width: var(--layout-icon-new-width, 160px);
  height: var(--layout-icon-new-height, 48px);
  margin-top: var(--layout-icon-margin-top, 20px);
  margin-left: var(--layout-icon-margin-left, 20px);
}

.inform-title, .inform-subtitle {
  background-color: rgba(255, 255, 255, 0);
  font-size: var(--inform-title-font-size, 24px);
  color: #333333;
  width: 100%;
  text-align: center;
  line-height: normal;
  letter-spacing: 0.4px;
}

.inform-title {
  padding-top: var(--inform-title-padding-top, 17px);
}

.inform-subtitle {
  padding: 0;
  margin: 0;
  font-weight: bolder;
  padding-bottom: var(--inform-title-padding-bottom, 15px);
}

.trybuy-purchase-counter-back {
  height: var(--counter-back-height, 118px);
  padding: var(--default-padding-2, 2px 2px 2px 2px);
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.trybuy-purchase-counter {
  width: 100%;
  margin-top: var(--slider-margin-top, 9px);
  box-sizing: border-box;
  text-align: center;
  line-height: normal;
  height: 30px;
}

.inform-span-info-min {
  font-size: var(--slider-font-size, 17px);
  text-align: right;
  margin-right: var(--slider-text-space, 8px);
}

.inform-span-info-max {
  font-size: var(--slider-font-size, 17px);
  text-align: left;
  margin-left: var(--slider-text-space, 8px);
}

.inform-slider{
  -webkit-appearance: none;
  width: var(--slider-width, 408px);
  height: var(--slider-height, 6px);
  padding: var(--default-padding-2, 2px 2px 2px 2px);
  border-radius: var(--slider-border-radius, 5px);
  border: 2px solid #761ef3;
  background-color: #761ef3;
  box-sizing: border-box;
}

.inform-bottom-content {
  width: 100%;
  margin-top: var(--inform-bottom-margin, 55px);
  font-size: var(--inform-bottom-font-size, 1.2em);
}

.inform-sel-users {
  font-size: var(--sel-users-font-size, 16px);
  text-align: center;
  color: #333333;
}

.inform-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: var(--slider-ball-size, 20px);
  height: var(--slider-ball-size, 20px);
  background: white;
  border-radius: 50%;
  box-shadow: 0px 1px 3px 1px black;
  cursor: pointer;
}

.inform-foot-desc {
  color: #0f0101;
  text-align: center;
  line-height: normal;
}

.inform-foot-sub-desc {
  color: blue;
  text-align: center;
}
