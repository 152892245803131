@import '../../styles/global_variables';

.trybuy-user-count {
    position: absolute;
    top: var(--user-count-top, 259px);
    width: var(--layout-width, 1434px);
    height: var(--user-count-height, 48px);
    display: flex;

  p {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0px;
    width: var(--user-count-p-width, 600px);
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    font-size: var(--user-count-p-font-size, 21px);
    color: #ffffff;
    text-align: right;
    line-height: normal;
  }
}

.trybuy-user-count-input {
  width: var(--user-count-input-width, 120px);
  height: var(--user-count-height, 48px);
  margin-left: var(--user-count-input-margin-left, 20px);
  padding: var(--default-padding-2, 2px 2px 2px 8px);
  border-radius: var(--user-count-input-radius, 8px);
  border: var(--default-border, 1px solid #e8e8e8);
  background-color: #ffffff;
  box-sizing: border-box;
  font-family: "Inter Light", "Inter", sans-serif;
  font-weight: 300;
  font-size: var(--user-count-input-font-size, 24px);
  color: #000000;

  .ant-input-number-input {
    padding: 0;
  }
}

.trybuy-user-count-input:disabled {
  background-color: #f0f0f0;
  box-sizing: border-box;
}

.trybuy-message-error {
    position: absolute;
    top: var(--user-count-top, 259px);
    width: var(--layout-width, 1434px);
    height: var(--user-count-height, 48px);
    display: block;

    p {
      position: absolute;
      right: 0px;
      top: var(--error-message-top, 14px);
      width: var(--error-message-width, 680px);
      font-family: "Inter Light", "Inter", sans-serif;
      font-size: var(--error-message-font-size, 16px);
      color: yellow;
    }
}