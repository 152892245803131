@import 'styles/global_variables';

.ant-layout {
  width: var(--layout-width, 1434px);
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Inter", sans-serif;
  font-size: 10px;
}

.ant-layout-header {
  width: var(--layout-width, 1434px);
  height: var(--layout-header-height, 64px);
  padding: var(--default-padding-2, 2px 2px 2px 2px);
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
}

.litmos-icon {
  position: absolute;
  top: var(--layout-icon-top, 7px);
  left: var(--layout-icon-left, 16px);
  width: var(--layout-icon-width, 160px);
  height: var(--layout-icon-height, 48px);
  box-sizing: border-box;
}

.litmos-feature-icon, .litmos-feature-hidden-icon {
  width: var(--layout-icon-feature-width, 160px);
  height: var(--layout-icon-feature-height, 48px);
}

.litmos-feature-hidden-icon {
  visibility: hidden;
}

