@import '../../styles/global_variables';

.trybuy-title {
  height: var(--trybuy-title-height, 267px);
  padding: var(--default-padding-2, 2px 2px 2px 2px);
  background-image: linear-gradient(90deg, #7f35ff 0%, #0695f9 99.83347210657786%);
  box-sizing: border-box;

  .title {
    margin-top: var(--trybuy-title-margin-top, 11px);
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-size: var(--trybuy-title-font-size, 48px);
    color: #ffffff;
    text-align: center;
    line-height: normal;
  }

  .description {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    margin-top: var(--trybuy-desc-margin-top, 9px);
    font-size: var(--trybuy-desc-font-size, 36px);
    color: #ffffff;
    text-align: center;
    line-height: normal;
  }
}
